import React, { FC } from 'react';

const Code128: FC<{
  height?: number | string;
  width?: number | string;
}> = ({ height = 50, width = 250 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      version='1'
      viewBox='0 0 681 246'
    >
      <path
        d='M80 1230V80h90v2300H80V1230zM210 1230V80h50v2300h-50V1230zM350 1230V80h50v2300h-50V1230zM580 1230V80h50v2300h-50V1230zM720 1230C720 87 720 80 740 80s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM950 1230V80h90v2300h-90V1230zM1090 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM1180 1230V80h90v2300h-90V1230zM1360 1230V80h50v2300h-50V1230zM1590 1230V80h50v2300h-50V1230zM1680 1230V80h190v2300h-190V1230zM1960 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM2100 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM2230 1230V80h190v2300h-190V1230zM2460 1230V80h50v2300h-50V1230zM2600 1230V80h190v2300h-190V1230zM2880 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM2970 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM3110 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM3240 1230V80h50v2300h-50V1230zM3340 1230V80h90v2300h-90V1230zM3610 1230V80h90v2300h-90V1230zM3800 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM3890 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM4120 1230V80h90v2300h-90V1230zM4300 1230V80h50v2300h-50V1230zM4390 1230V80h50v2300h-50V1230zM4620 1230V80h50v2300h-50V1230zM4710 1230V80h100v2300h-100V1230zM4900 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM5130 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM5260 1230V80h190v2300h-190V1230zM5490 1230V80h50v2300h-50V1230zM5630 1230V80h50v2300h-50V1230zM5720 1230V80h190v2300h-190V1230zM6050 1230c0-1143 0-1150 20-1150s20 7 20 1150 0 1150-20 1150-20-7-20-1150zM6140 1230V80h90v2300h-90V1230zM6370 1230V80h130v2300h-130V1230zM6550 1230V80h50v2300h-50V1230zM6640 1230V80h90v2300h-90V1230z'
        transform='matrix(.1 0 0 -.1 0 246)'
      ></path>
    </svg>
  );
};

export default Code128;
