import React, { FC } from 'react';

const EmailIcon: FC<{
  fill?: string;
  height?: number | string;
  width?: number | string;
}> = ({ fill = '#404040', height = 10, width = 13 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 13 10'
    >
      <path
        fill={fill}
        fillRule='evenodd'
        d='M.93.044a1.232 1.232 0 00-.891 1.48c.05.196.143.372.271.508.114.121 5.522 3.828 5.72 3.92.301.14.661.135.969-.014.241-.117 5.54-3.752 5.674-3.892.632-.663.287-1.785-.616-2.004C11.82-.015 1.162-.014.93.044zM0 5.84C0 7.95.009 8.916.028 9.012c.093.459.455.833.914.944.239.058 10.833.06 11.1.002.461-.1.835-.48.929-.946.032-.159.04-6.21.009-6.195-.01.005-1.238.843-2.728 1.863C8.762 5.699 7.46 6.575 7.36 6.627c-.281.145-.519.2-.862.2-.342 0-.58-.055-.86-.2-.1-.052-1.401-.928-2.892-1.947A769.266 769.266 0 00.02 2.817C.009 2.813 0 4.173 0 5.841z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export default EmailIcon;
