import React, { FC } from 'react';

const PositionIcon: FC<{
  fill?: string;
  height?: number | string;
  width?: number | string;
}> = ({ fill = '#404040', height = 14, width = 10 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 10 14'
    >
      <path
        fill={fill}
        fillRule='evenodd'
        d='M4.253.016C2.448.232.965 1.372.309 3.048a4.657 4.657 0 00.082 3.54c.155.354 3.67 6.616 3.787 6.748.058.064.174.149.26.19a.66.66 0 00.36.074c.265-.001.449-.077.608-.253.127-.139 3.626-6.362 3.798-6.754a4.672 4.672 0 00.358-2.456A4.768 4.768 0 005.603.053a7.422 7.422 0 00-1.35-.037zm1.132 2.405c.31.096.54.236.792.482.249.243.392.466.496.772.064.187.072.254.072.572 0 .317-.008.384-.072.572a1.825 1.825 0 01-.496.772c-.25.243-.478.383-.792.484-.193.062-.261.07-.587.07-.325 0-.394-.008-.586-.07-.312-.1-.54-.24-.792-.484a1.816 1.816 0 01-.497-.772c-.063-.188-.072-.255-.072-.572 0-.314.009-.385.07-.564.107-.317.25-.543.497-.783a1.941 1.941 0 011.04-.543c.239-.042.683-.012.927.064z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export default PositionIcon;
