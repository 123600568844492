import React from 'react';
function Train() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='30'
      fill='none'
      viewBox='0 0 20 30'
    >
      <path
        fill='#000'
        fillRule='evenodd'
        d='M6 0a5 5 0 00-5 5v10a5.002 5.002 0 003.783 4.85l-1.549 2.5-1.6 2.712C.882 26.343.47 27.055.249 27.483c-.078.149-.13.264-.168.356a.947.947 0 00-.074.267c-.035.37.09.7.318.922.184.18.434.287.72.287.6 0 .763-.15 1.474-1.365l.54-.922L4.257 25h11.322l1.197 2.027.51.875c.74 1.266.895 1.412 1.504 1.412.639 0 1.1-.539 1.04-1.21-.028-.279-.278-.748-1.63-3.039l-1.597-2.709-1.403-2.5A5.002 5.002 0 0019 15V5A4.982 4.982 0 0016.553.7 4.974 4.974 0 0014 0H6zm6.768 20H7.203l-1.457 2.459L5.43 23h8.974l-.318-.541L12.768 20zM14 1H6v3h8V1zM3 7a2 2 0 012-2h10a2 2 0 012 2v4a1.919 1.919 0 01-.084.577A1.995 1.995 0 0115 13H5a2 2 0 01-2-2V7zm1.5 11A1.497 1.497 0 006 16.5 1.5 1.5 0 104.5 18zM17 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Train;
