import React from 'react';
function Boat() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='33'
      height='32'
      fill='none'
      viewBox='0 0 33 32'
    >
      <path
        fill='#000'
        fillRule='evenodd'
        d='M11.322.32c-.365.208-.533.671-.533 1.473 0 1.14-.027 1.17-1.066 1.17-1.015 0-1.066.05-1.066 1.037 0 .94-.08 1.037-.856 1.037h-.857l-.972 6.147c-.534 3.38-.97 6.302-.97 6.493 0 .19 2.563-1.176 5.695-3.037 3.132-1.86 5.778-3.382 5.879-3.382.1 0 2.767 1.535 5.925 3.41 3.158 1.876 5.786 3.367 5.84 3.315.054-.053-.239-2.888-.652-6.299-.413-3.411-.752-6.303-.755-6.425-.002-.122-.346-.222-.765-.222-.667 0-.761-.128-.761-1.037 0-.987-.051-1.037-1.066-1.037-.963 0-1.066-.08-1.066-.822 0-.453-.214-1.12-.474-1.482-.95-1.318-2.572-.498-2.572 1.299v1.025L16.956 2.9l-3.273-.084-.101-1.035c-.163-1.664-.974-2.188-2.26-1.46zm1.283 7.22c.201.237.319.67.261.963-.089.448-.421.533-2.077.533-1.655 0-1.988-.085-2.076-.533-.182-.916.528-1.393 2.076-1.393.895 0 1.589.165 1.816.43zm5.786 0c.202.237.32.67.262.963-.09.448-.422.533-2.077.533s-1.988-.085-2.077-.533c-.181-.916.528-1.393 2.077-1.393.894 0 1.588.165 1.815.43zm5.786 0c.202.237.32.67.262.963-.089.448-.422.533-2.077.533s-1.988-.085-2.076-.533c-.182-.916.528-1.393 2.076-1.393.895 0 1.589.165 1.815.43zM7.767 18.016C3.506 20.571.011 22.744 0 22.843c-.012.1.925 2.201 2.082 4.67L4.186 32l12.782-.077 12.782-.077 1.759-4.444c1.33-3.361 1.673-4.527 1.407-4.786-.49-.478-15.468-9.343-15.588-9.227-.054.053-.196 2.994-.317 6.536-.122 3.542-.337 6.44-.48 6.44-.143 0-.26-1.032-.261-2.295-.002-3.698-.382-10.74-.58-10.72-.098.01-3.665 2.11-7.924 4.665z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Boat;
