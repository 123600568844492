import React from 'react';
function Bus() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='31'
      fill='none'
      viewBox='0 0 26 31'
    >
      <path
        fill='#000'
        fillRule='evenodd'
        d='M4.865.09C3.437.323 2.252.91 1.396 1.81.805 2.43.41 3.164.156 4.113L0 4.7v20.605l.135.342c.263.679.75 1.08 1.465 1.21l.351.064.026 1.252c.015.808.043 1.25.142 1.574.078.256.203.438.402.667.387.445.735.583 1.465.585.526.002.621-.015.92-.167.389-.197.655-.488.85-.93.197-.446.252-.877.252-2.006v-.986h13.951l.002.986c0 1.129.055 1.56.252 2.007.195.441.46.732.85.93.298.15.396.168.92.167.663 0 1.015-.123 1.367-.471.515-.511.652-1.045.654-2.538v-1.072l.338-.071c.596-.125 1.043-.406 1.318-.821.131-.198.223-.425.274-.68.06-.3.08-9.756.056-15.772-.01-2.652-.027-4.635-.054-4.874-.172-1.554-.778-2.841-1.682-3.577-.68-.551-1.455-.883-2.408-1.033-.778-.12-16.237-.12-16.98 0zM5 3.5A1.5 1.5 0 016.5 2h13a1.5 1.5 0 010 3h-13A1.5 1.5 0 015 3.5zM5 8a2 2 0 00-2 2v4a2 2 0 002 2h16a2 2 0 002-2v-4a2 2 0 00-2-2H5zm18 13a2 2 0 11-4 0 2 2 0 014 0zM5 23a2 2 0 100-4 2 2 0 000 4z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Bus;
